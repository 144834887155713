/*Phones*/
@media only screen and (max-width: 767px){
    .nomatch-container{
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 50%;
        left: 50%;
        position: fixed;
        transform: translate(-50%, -50%);
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 85%;
    }
    
    .nomatch-container h2{
        font-size: 40px;
    }

    .nomatch-return-button{
        outline: none;
        border: 2px solid;
        border-radius: 20px;
        cursor: pointer;
        padding: 10px;
        font-size: 20px;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px ) and (max-width: 1024px){
    .nomatch-container{
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 50%;
        left: 50%;
        position: fixed;
        transform: translate(-50%, -50%);
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 85%;
    }
    
    .nomatch-container h2{
        font-size: 70px;
    }

    .nomatch-return-button{
        outline: none;
        border: 2px solid;
        border-radius: 20px;
        cursor: pointer;
        padding: 20px;
        font-size: 30px;
    }
}

/*Desktop*/
@media only screen and (min-width: 1025px){
    .nomatch-container{
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 50%;
        left: 50%;
        position: fixed;
        transform: translate(-50%, -50%);
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center center;
        width: 100%;
        height: 85%;
    }
    
    .nomatch-container h2{
        font-size: 80px;
    }

    .nomatch-return-button{
        outline: none;
        border: 2px solid;
        border-radius: 20px;
        cursor: pointer;
        padding: 20px;
        font-size: 30px;
    }
}
