
/*Phones*/
@media only screen and (min-width: 320px) and (max-width: 767px){

    .grid-button-display{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }

    .grid-item h5{
        font-size: 18px;
    }

    .custom-padding{
        padding-top: 8px;
    }

    .homepage-container{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding:3%;
    }
    
    .button-display{
        display: none;
    }

    .headline{
        font-size: 38px;
        padding-top: 6%;
        color: rgb(100,100,100);
        text-transform: uppercase;
        letter-spacing: 5px;
        text-decoration: none;
        text-align: center;
    }

    .slogan{
        font-size: 25px;
        padding: 2%;
        color: rgb(100,100,100);
        text-transform: uppercase;
        letter-spacing: 5px;
        text-decoration: none;
        text-align: center;
    }

    .btn-products{
        border: 2px solid lightsalmon;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 76px;
        width: 76px;
    }

    .btn-contacts{
        border: 2px solid lightskyblue;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 76px;
        width: 76px;
    }

    .btn-lazada{
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: url('../../assets/images/general/lazada-logo.png');
        background-size: cover;
        background-position: center center;
        height: 80px;
        width: 80px;
    }
    
    .btn-shopee{
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: url('../../assets/images/general/shopee-logo.png');
        background-size: cover;
        background-position: center center;
        height: 80px;
        width: 80px;
    }

    .btn-facebook{
        border: 2px solid rgb(66,103,178);
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 76px;
        width: 76px;
        color: rgb(66,103,178);
    }

    .btn-custom-order{
        border: 2px solid lightseagreen;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 76px;
        width: 76px;
    }

    .custom-order-icon{
        color: lightseagreen;
    }

    .contacts-icon{
        color: lightskyblue;
    }

    .products-icon{
        color: lightsalmon;
    }

    .grid-button-display-tablet{
        display: none;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 1024px){

    .grid-button-display-tablet{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }
    
    .grid-button-display{
        display: none;
    }

    .homepage-container{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        padding: 5%;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    
    .button-display{
        display: none;
    }
    
    .headline{
        font-size: 50px;
        color: rgb(100,100,100);
        text-transform: uppercase;
        letter-spacing: 20px;
        text-decoration: none;
        text-align: center;
    }
    
    .slogan{
        padding: 2%;
        font-size: 40px;
        color: rgb(100,100,100);
        text-transform: uppercase;
        letter-spacing: 20px;
        text-decoration: none;
        text-align: center;
    }
    
    .btn-lazada{
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: url('../../assets/images/general/lazada-logo.png');
        background-size: cover;
        background-position: center center;
        height: 100px;
        width: 100px;
    }
    
    .btn-shopee{
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: url('../../assets/images/general/shopee-logo.png');
        background-size: cover;
        background-position: center center;
        height: 100px;
        width: 100px;
    }

    .btn-facebook{
        border: 2px solid rgb(66,103,178);
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
        color: rgb(66,103,178);
    }

    .btn-products{
        border: 2px solid lightsalmon;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .btn-contacts{
        border: 2px solid lightskyblue;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .btn-custom-order{
        border: 2px solid lightseagreen;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .custom-order-icon{
        color: lightseagreen;
    }

    .contacts-icon{
        color: lightskyblue;
    }

    .products-icon{
        color: lightsalmon;
    }

    .button-display li h5{
        font-size: 24px;
    }

    .custom-padding{
        padding-top: 8px;
    }

}

/*Desktop*/
@media only screen and (min-width: 1025px){

    .grid-button-display{
        display: none;
    }

    .grid-button-display-tablet{
        display: none;
    }
    
    .homepage-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        margin: 0 auto;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: 700px;
        background-position: center center;
    }
    
    .button-display{
        padding: 3%;
        list-style: none;
        display: flex;
        justify-content: space-evenly;
    }
    
    .headline{
        font-size: 90px;
        color: rgb(100,100,100);
        text-transform: uppercase;
        letter-spacing: 10px;
        text-decoration: none;
        text-align: center;
    }
    
    .slogan{
        font-size: 3.4rem;
        color: rgb(100,100,100);
        text-transform: uppercase;
        letter-spacing: 20px;
        text-decoration: none;
        text-align: center;
    }
    
    .btn-lazada{
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: url('../../assets/images/general/lazada-logo.png');
        background-size: cover;
        background-position: center center;
        height: 100px;
        width: 100px;
    }
    
    .btn-shopee{
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: url('../../assets/images/general/shopee-logo.png');
        background-size: cover;
        background-position: center center;
        height: 100px;
        width: 100px;
    }

    .btn-facebook{
        border: 2px solid rgb(66,103,178);
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
        color: rgb(66,103,178);
    }

    .btn-products{
        border: 2px solid lightsalmon;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .btn-contacts{
        border: 2px solid lightskyblue;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .btn-custom-order{
        border: 2px solid lightseagreen;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .custom-order-icon{
        color: lightseagreen;
    }

    .contacts-icon{
        color: lightskyblue;
    }

    .products-icon{
        color: lightsalmon;
    }

    .button-display li h5{
        font-size: 28px;
    }

    .custom-padding{
        padding-top: 8px;
    }

}
