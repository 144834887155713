.toggle-button{
    height: 1.3rem;
    width: 1.8rem;
    background: transparent;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.toggle-button:focus{
    outline: none;
}

.toggle-button-line{
    width: 1.7rem;
    height: 2px;
    background-color: rgb(100,100,100);
}