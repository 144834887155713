:root{
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --text-primary: white;
    --text-secondary: white;
    --bg-primary: #23232e;
    --bg-secondary: # 141418;
}

html{
    height: 100vh;
}

body{
    height: 100vh;
}