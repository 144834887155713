@media only screen and (min-width: 320px) and (max-width: 767px){
    /* phones*/

    .card{
        display: grid;
        grid-template-columns: 270px;
        grid-template-rows: 180px 180px 50px;
        grid-template-areas: "image" "text" "redirection";
        box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
        text-align: center;
        border-radius: 18px;
        background: white;
        overflow: hidden;
        transition: 0.5s ease;
    }

    .card-headline{
        font-size: 1.4rem;
    }
    
    .card-description{
        padding: 1%;
    }
    
    .card-description ul{
        text-decoration: none;
        list-style: none;
        text-align: center;
    }
    
    .card-description ul li{
        padding-top: 3%;
    }
    
    .card-text{
        grid-area: text;
        margin: 15px;
    }
    
    .card-redirection{
        grid-area: "redirection";
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: rgb(10, 153, 10);
    }
    
    .card-redirection .redirection{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        color: white;
        font-weight: 300;
        font-size: 1rem;
    }
    
    .redirection a{
        text-decoration: none;
        color: white;
    }

    .redirection li{
        list-style: none;
    }

    .card-redirection .border{
        border-left: 1px solid white;
        border-right: 1px solid white;
    }
    
}

/*Tablet*/
@media only screen and (min-width: 768px ) and (max-width: 1024px){
    .card{
        display: grid;
        grid-template-columns: 300px;
        grid-template-rows: 210px 210px 80px;
        grid-template-areas: "image" "text" "redirection";
        box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
        text-align: center;
        border-radius: 18px;
        background: white;
        overflow: hidden;
        transition: 0.5s ease;
    }
    
    .card-headline{
        font-size: 1.5rem;
    }
    
    .card-description{
        padding: 3%;
    }
    
    .card-description ul{
        text-decoration: none;
        list-style: none;
        text-align: center;
    }
    
    .card-description ul li{
        padding-top: 5%;
    }

    .redirection a{
        text-decoration: none;
        color: white;
    }

    .redirection li{
        list-style: none;
    }
    
    .card-text{
        grid-area: text;
        margin: 25px;
    }
    
    .card-redirection{
        grid-area: "redirection";
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: rgb(10, 153, 10);
    }
    
    .card-redirection .redirection{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        color: white;
        font-weight: 300;
        font-size: 1rem;
    }
    
    .card-redirection .border{
        border-left: 1px solid white;
        border-right: 1px solid white;
    }
    
    .card:hover{
        transform: scale(1.1);
        box-shadow: rgba(0,0,0,0.5);
    }
}

/*Desktop*/
@media only screen and (min-width: 1025px){

    .card{
        display: grid;
        grid-template-columns: 300px;
        grid-template-rows: 210px 210px 80px;
        grid-template-areas: "image" "text" "redirection";
        box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
        text-align: center;
        border-radius: 18px;
        background: white;
        overflow: hidden;
        transition: 0.5s ease;
    }
    
    .card-headline{
        font-size: 1.5rem;
    }
    
    .card-description{
        padding: 3%;
    }
    
    .card-description ul{
        text-decoration: none;
        list-style: none;
        text-align: center;
    }
    
    .card-description ul li{
        padding-top: 5%;
    }
    
    .card-text{
        grid-area: text;
        margin: 25px;
    }
    
    .card-redirection{
        grid-area: "redirection";
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: rgb(10, 153, 10);
    }
    
    .card-redirection .redirection{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        color: white;
        font-weight: 300;
        font-size: 1rem;
    }

    .redirection a{
        text-decoration: none;
        color: white;
    }

    .redirection li{
        list-style: none;
    }
    
    .card-redirection .border{
        border-left: 1px solid white;
        border-right: 1px solid white;
    }
    
    .card:hover{
        transform: scale(1.1);
        box-shadow: rgba(0,0,0,0.5);
    }
}