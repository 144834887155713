/*Phones*/
@media only screen and (max-width: 767px){

    .itempage-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        background: url('../../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-position: center center;
        overflow: auto;
    }

    .itempage-carousel{
        padding: 50px;
    }
    
    .itempage-title{
        padding: 20px;
        text-align: center;
    }

    .itempage-title h3{
        font-size: 30px;
    }

    .itempage-description-title{
        padding: 10px;
        padding-top: 0px;
        text-align: center;
    }

    .itempage-description-title h3{
        font-size: 24px;
    }

    .itempage-description{
        width: 300px;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        margin: 0 auto;
    }

    .itempage-description ul{
        text-align: left;
        list-style: none;
    }

    .itempage-description ul li{
        padding: 2%;
        font-size: 18px;
    }

    .itempage-button-redirect{
        padding: 20px;
        width: 300px;
        text-align: center;
    }

    .itempage-button-redirect h4{
        font-size: 26px;
        padding: 10px;
    }

    .button-list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 2%;
    }

    .button-list-lazada{
        background: url('../../../assets/images/general/lazada-logo.png');
        background-size: cover;
        background-position: center center;
        height: 80px;
        width: 80px;
        cursor: pointer;
        border: none;
        border-radius: 20px;
        outline: none;
    }

    .button-list-shopee{
        background: url('../../../assets/images/general/shopee-logo.png');
        background-size: cover;
        background-position: center center;
        height: 80px;
        width: 80px;
        cursor: pointer;
        border-radius: 20px;
        border: none;
        outline: none;
    }

    .desktop{
        display: none;
    }

    .tablet{
        display: none;
    }

}

/*Tablet*/
@media only screen and (min-width: 768px ) and (max-width: 1024px){
    .itempage-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        background: url('../../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-position: center center;
        overflow: auto;
    }

    .itempage-carousel{
        padding: 2%;
    }
    
    .itempage-title{
        padding: 3%;
        text-align: center;
    }

    .itempage-title h3{
        font-size: 40px;
    }

    .itempage-description-title{
        padding: 2%;
        padding-top: 0px;
        text-align: center;
    }

    .itempage-description-title h3{
        font-size: 30px;
    }

    .itempage-description{
        padding: 20px;
        width: 650px;
        text-align: center;
    }

    .itempage-description ul{
        text-align: left;
        list-style: none;
    }

    .itempage-description ul li{
        padding: 2%;
        font-size: 20px;
    }

    .itempage-button-redirect{
        padding: 20px;
        width: 650px;
        text-align: center;
    }

    .itempage-button-redirect h4{
        font-size: 30px;
        padding: 10px;
    }

    .button-list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 2%;
    }

    .button-list-lazada{
        background: url('../../../assets/images/general/lazada-logo.png');
        background-size: cover;
        background-position: center center;
        height: 110px;
        width: 110px;
        cursor: pointer;
        border: none;
        border-radius: 20px;
        outline: none;
    }

    .button-list-shopee{
        background: url('../../../assets/images/general/shopee-logo.png');
        background-size: cover;
        background-position: center center;
        height: 110px;
        width: 110px;
        cursor: pointer;
        border-radius: 20px;
        border: none;
        outline: none;
    }

    .desktop{
        display: none;
    }

    .phone{
        display: none;
    }

}

/*Desktop*/
@media only screen and (min-width: 1025px){
    .itempage-container{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 100%;
        background: url('../../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-position: center center;
        overflow: auto;
    }

    .itempage-carousel{
        padding: 30px;
    }
    
    .itempage-title{
        padding: 20px;
        text-align: center;
    }

    .itempage-title h3{
        font-size: 50px;
    }

    .itempage-description-title{
        padding: 20px;
        padding-top: 0px;
        text-align: center;
    }

    .itempage-description-title h3{
        font-size: 40px;
    }

    .itempage-description{
        padding: 10px;
        width: 650px;
        text-align: center;
    }

    .itempage-description ul{
        text-align: left;
        list-style: none;
    }

    .itempage-description ul li{
        padding: 2%;
        font-size: 25px;
    }

    .itempage-button-redirect{
        padding: 20px;
        width: 650px;
        text-align: center;
    }

    .itempage-button-redirect h4{
        font-size: 30px;
        padding: 10px;
    }

    .button-list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 2%;
    }

    .button-list-lazada{
        background: url('../../../assets/images/general/lazada-logo.png');
        background-size: cover;
        background-position: center center;
        height: 110px;
        width: 110px;
        cursor: pointer;
        border: none;
        border-radius: 20px;
        outline: none;
    }

    .button-list-shopee{
        background: url('../../../assets/images/general/shopee-logo.png');
        background-size: cover;
        background-position: center center;
        height: 110px;
        width: 110px;
        cursor: pointer;
        border-radius: 20px;
        border: none;
        outline: none;
    }

    .tablet{
        display: none;
    }

    .phone{
        display: none;
    }

}
