.navigationbar-header{
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
}

.navigationbar-nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90px;
    padding: 0 1rem;
}

.navigationbar-logo{
    color: rgb(100,100,100);
    text-decoration: none;
    margin-left: 1rem;
}

.navigationbar-button{
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    height: 120px;
    width: 120px;
    background: url('../../assets/images/general/brycen-packaginglogo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
}

.navigationbar-links{
    display: flex;
    justify-content: space-between;
}

.navigationbar-item{
    list-style: none;
    padding: 10px 20px;
}

.navigationbar-item:hover{
    background: rgba(10, 153, 10, 0.5);
    border-radius: 20px;
    
}

.navigationbar-links a{
    color: black;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 1.0rem;
}

/*Spacer*/
.spacer{
    flex: 1;
}

@media (max-width: 768px){
    .navigationbar-links{
        display: none;
    }
}

@media (min-width: 769px){
    .navigationbar-burger{
        display: none;
    }
}