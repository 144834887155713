.side-drawer{
    height:100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 15rem;
    max-width: 20rem;
    z-index: 200;
    transform: translateX(-100%);
    overflow: auto;
    transition: transform 0.3s ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}


.side-drawer-items{
    list-style: none;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid;
}

.side-drawer-items li{
    text-align: left;
    justify-content: center;
    padding: 7%;
}

.side-drawer a{
    text-decoration: none;
    color: rgb(100,100,100);
    font-size: 1.3rem;
}

.side-drawer-close{
    border-bottom: 2px solid;
}

.side-drawer-close button{
    background: none;
    outline: none;
    border: none;
    padding: 2%
}

.side-drawer-title{
    display: flex;
    text-align: center;
    justify-content: center;
    border-bottom: 2px solid;
    padding: 10% 
}

.side-drawer-lazada{
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    background: url('../../assets/images/general/lazada-logo.png');
    background-size: cover;
    background-position: center center;
    height: 90px;
    width: 90px;
}

.side-drawer-shopee{
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    background: url('../../assets/images/general/shopee-logo.png');
    background-size: cover;
    background-position: center center;
    height: 90px;
    width: 90px;
}

.side-drawer-logo{
    background: url('../../assets/images/general/brycen-packaginglogo.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    outline: none;
    cursor: pointer;
    height: 100px;
    width: 190px;
}

.side-drawer-links{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.side-drawer-links li{
    padding: 7%;
}

.side-drawer-links h5{
    font-size: 20px;
    padding: 7%;
}

@media (min-width: 769px){
    .side-drawer{
        display: none;
    }
}