/*Phones*/
@media only screen and (min-width: 320px) and (max-width: 767px){
    .custom-order-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: 800px;
        background-position: center center;
    }
    
    .service-container{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }
    
    .grid-item img{
        height: 200px;
        width: 290px;
        object-fit: cover;
        border-radius: 2%;
    }

    .grid-item h5{
        padding: 2%;
        font-size: 18px;
    }

    .custom-order-headline{
        display: flex;
        flex-direction: column;
        padding: 2%;
        font-size: 25px;
    }

    .interest-call{
        display: flex;
        flex-direction: column;
        font-size: 25px;
        padding: 3%;
    }

    .contact-section{
        padding: 2%;
    }

    .tablet-desktop{
        display: none;
    }

}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .custom-order-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: 800px;
        background-position: center center;
    }
    
    .service-container{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }
    
    .grid-item img{
        height: 400px;
        width: 590px;
        object-fit: cover;
        border-radius: 2%;
    }

    .grid-item h5{
        padding: 2%;
        font-size: 25px;
    }

    .custom-order-headline{
        display: flex;
        flex-direction: column;
        padding: 2%;
        font-size: 30px;
    }

    .interest-call{
        display: flex;
        flex-direction: column;
        font-size: 30px;
        padding: 3%;
    }

    .contact-section{
        padding: 2%;
    }

    .phone{
        display: none;
    }

}

/*Desktop*/
@media only screen and (min-width: 1025px){
    .custom-order-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        margin: 0 auto;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-size: 800px;
        background-position: center center;
    }
    
    .service-container{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto auto auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }
    
    .grid-item img{
        height: 400px;
        width: 590px;
        object-fit: cover;
        border-radius: 2%;
    }

    .grid-item{
        padding: 2%;
    }

    .grid-item h5{
        padding: 2%;
        font-size: 25px;
    }

    .custom-order-headline{
        display: flex;
        flex-direction: column;
        padding: 2%;
        font-size: 30px;
    }

    .interest-call{
        display: flex;
        flex-direction: column;
        font-size: 30px;
        padding: 3%;
    }

    .contact-section{
        padding: 2%;
    }

    .phone{
        display: none;
    }


    
}