/*Phones*/
@media only screen and (min-width: 320px) and (max-width: 767px){
    .carousel-container{
        height: 250px;
        width: 300px;
        margin: 0 auto;
    }

    img{
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .carousel-container .tablet-desktop{
        display: none;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px ) and (max-width: 1024px){
    .carousel-container{
        height: auto;
        width: 600px;
        margin: 0 auto;
    }

    img{
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    
    .slick-prev:before, .slick-next:before { 
        color:black !important;
    }

    .carousel-container .phone{
        display: none;
    }
}

/*Desktop*/
@media only screen and (min-width: 1025px){
    .carousel-container{
        height: 600px;
        width: 700px;
        margin: 0 auto;
    }
    
    img{
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .carousel-container .phone{
        display: none;
    }

    .slick-prev:before, .slick-next:before { 
        color:black !important;
    }
}