/*Phones Portrait View*/
@media only screen and (min-width: 320px) and (max-width: 767px){
    .contact-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        padding-top: 5%;
        background: url('../../assets/images/general/tree60.png');
        background-size: cover;
        background-position: center center;
    }

    .contact-description{
        padding: 2%;
        font-size: 20px;
    }

    .contact-links{
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: space-around;
    }

    .contact-links ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
    }

    .contact-links-list-lazada{
        background: url('../../assets/images/general/lazada-logo.png');
        height: 80px;
        width: 80px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .contact-links-list-shopee{
        background: url('../../assets/images/general/shopee-logo.png');
        height: 80px;
        width: 80px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .contact-links-list-email-phone{
        background: none;
        height: 76px;
        width: 76px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
    }

    .contact-links-list li{
        padding: 3%;
    }

    .contact-links-list h5{
        font-size: 18px;
    }

    .tablet-desktop{
        display: none;
    }

    .contact-links-list-whatsapp-phone{
        border: 2px solid darkgreen;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 76px;
        width: 76px;
    }

    .contact-links-list-facebook-phone{
        border: 2px solid rgb(66,103,178);
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 76px;
        width: 76px;
        color: rgb(66,103,178);
    }

    .whatsapp-icon{
        color: green;
    }

    .grid-item{
        padding: 2%;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px ) and (max-width: 1024px){
    .contact-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        padding-top: 5%;
        background: url('../../assets/images/general/tree60.png');
        background-size: cover;
        background-position: center center;
    }

    .contact-description{
        padding: 2%;
        font-size: 40px;
    }

    .contact-links-list{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }

    .contact-links ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
    }

    .contact-links-list-lazada{
        background: url('../../assets/images/general/lazada-logo.png');
        height: 100px;
        width:100px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .contact-links-list-shopee{
        background: url('../../assets/images/general/shopee-logo.png');
        height: 100px;
        width:100px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .contact-links-list-email{
        background: none;
        height: 100px;
        width:100px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        size: 100px;
    }

    .contact-links-list li{
        padding: 3%;
    }

    .contact-links-list h5{
        font-size: 30px;
    }

    .contact-links-list-whatsapp{
        border: 2px solid darkgreen;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .contact-links-list-facebook{
        border: 2px solid rgb(66,103,178);
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
        color: rgb(66,103,178);
    }

    .whatsapp-icon{
        color: green;
    }

    .phone{
        display: none;
    }

}

/*Desktop*/
@media only screen and (min-width: 1025px){

    .contact-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        padding-top: 5%;
        background: url('../../assets/images/general/tree60.png');
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contact-description{
        padding: 2%;
        font-size: 40px;
    }

    .contact-links-list{
        display: grid;
        padding-top: 4%;
        grid-template-columns: auto auto;
        justify-content: space-evenly;
        align-content: center;
        grid-row-gap: 20px;
    }

    .contact-links ul{
        list-style: none;
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
    }

    .contact-links-list-lazada{
        background: url('../../assets/images/general/lazada-logo.png');
        height: 100px;
        width:100px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .contact-links-list-shopee{
        background: url('../../assets/images/general/shopee-logo.png');
        height: 100px;
        width:100px;
        background-position: center center;
        background-size: cover;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .contact-links-list-facebook{
        border: 2px solid rgb(66,103,178);
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
        color: rgb(66,103,178);
    }

    .contact-links-list-whatsapp{
        border: 2px solid darkgreen;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        background: white;
        background-size: cover;
        background-position: center center;
        height: 96px;
        width: 96px;
    }

    .whatsapp-icon{
        color: green;
    }

    .contact-links-list li{
        padding: 3%;
    }

    .contact-links-list h5{
        font-size: 25px;
    }

    .phone{
        display: none;
    }

}