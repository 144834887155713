:root{
    font-size: 16px;
    font-family: 'Arial';
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html, body{
    color:black;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: white;
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track{
    background: #1e1e24;
}

.App{
    height: 100vh;
}

.layout{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.main-container{
    flex: 1;
}