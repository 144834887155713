
/*Phones*/
@media only screen and (max-width: 767px){
    .product-container{
        display: flex;
        align-items: center;
        padding: 3%;
        width: 100%;
        justify-content: space-evenly;
        flex-direction: column;
        padding-top: 3.5rem;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .product-card{
        padding-bottom: 10%;
    }

    .center-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        width: 90%;
        height: 90%;
    }
}

/*Tablet*/
@media only screen and (min-width: 768px){
    .product-container{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
        padding-top: 3.5rem;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-position: center center;
    }

    .product-card{
        padding-bottom: 7%;
    }

    .center-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        width: 90%;
        height: 90%;
    }
}

/*Desktop*/
@media only screen and (min-width: 1025px){
    .product-container{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        margin: 0 auto;
        padding-top: 3.5rem;
        height: 100vh;
        background: url('../../assets/images/general/tree60.png');
        background-repeat: no-repeat;
        background-position: center center;
    }

    .center-container{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
    }
}